<template>
  <div
    :class="{ dark: this.$root.navigation.dark_mode }"
    class="component c-navigation"
    :style="
      this.$root.navigation.useCustomBackgroundColor
        ? { backgroundColor: this.$root.navigation.custom_background_color }
        : ''
    "
  >
    <div class="c-navigation__app-bar">
      <button
        role="button"
        class="c-navigation__menu-button c-navigation__icon"
        :aria-expanded="is_menu_open ? 'true' : 'false'"
        aria-label="Toggle main navigation menu"
        :class="is_menu_open ? 'is-active' : ''"
        @click="toggle_menu"
      >
        <feather v-if="!is_menu_open" type="menu"></feather>
        <feather v-if="is_menu_open" type="x"></feather>
      </button>
      <div
        class="
          c-navigation__home
          display-none
          bp-1024:margin-l-0
          bp-540:display-flex
        "
      >
        <a
          class="c-navigation__icon"
          :href="continue_url"
          aria-label="Go to homepage"
          ><feather type="home"></feather
        ></a>
      </div>
      <div
        class="c-navigation__offcanvas position-fixed"
        :class="is_menu_open ? 'is-open' : ''"
        @keyup.esc="close_menu"
      >
        <ul class="s-common__scrollbar c-navigation__items reset-list">
          <template v-for="(link, index) in links">
            <li
              :key="link.url"
              class="
                c-navigation__item c-navigation__item--level-1
                position-relative
                padding-y-24
                bp-1024:padding-y-48
                padding-x-24
                display-flex
                text-align-center
                justify-content-center
                align-items-center
              "
            >
              <span
                class="
                  display-none
                  bp-768:display-block
                  font-size-18
                  bp-1024:font-size-30
                  line-height-125
                  c-navigation__item--level-1-span
                "
                ><span v-if="index + 1 < 10">0</span>{{ index + 1 }}</span
              >
              <a
                class="
                  display-flex
                  align-items-center
                  c-navigation__url c-navigation__url--level-1
                  text-decoration-none
                "
                :class="current_section === link.url ? 'is-active' : ''"
                @click.prevent="scroll_to(link.url)"
                :title="link.navMenuText ? link.navMenuText : link.title"
                :tabindex="is_menu_open ? 0 : -1"
                :href="link.url"
                @focus="is_menu_open = true"
                @blur="is_menu_open = false"
                role="button"
              >
                <span
                  class="
                    margin-l-auto
                    font-weight-bold
                    text-transform-uppercase
                    font-size-30 font-weight-700
                    line-height-125
                    bp-1024:font-size-48 bp-1024:line-height-72
                  "
                  >{{ link.navMenuText ? link.navMenuText : link.title }}</span
                >
              </a>
            </li>
          </template>
          <template v-for="(link, index) in more_links">
            <li
              :key="link.url"
              class="
                c-navigation__item c-navigation__item--level-1
                position-relative
                padding-y-24
                bp-1024:padding-y-48
                padding-x-24
                display-flex
                justify-content-center
                align-items-center
              "
            >
              <span
                class="
                  display-none
                  bp-768:display-block
                  font-size-18
                  bp-1024:font-size-30
                  line-height-125
                  c-navigation__item--level-1-span
                "
                ><span v-if="index + links.length + 1 < 10">0</span
                >{{ index + links.length + 1 }}</span
              >
              <a
                class="
                  display-flex
                  align-items-center
                  c-navigation__url c-navigation__url--level-1
                  text-decoration-none
                "
                :title="link.title"
                :tabindex="is_menu_open ? 0 : -1"
                :href="link.url"
                @focus="is_menu_open = true"
                @blur="is_menu_open = false"
                role="button"
                target="_blank"
              >
                <span
                  class="
                    margin-l-auto
                    font-weight-bold
                    text-transform-uppercase text-align-right
                    font-size-30 font-weight-700
                    line-height-125
                    bp-1024:font-size-48 bp-1024:line-height-72
                  "
                  >{{ link.title }}</span
                >
              </a>
            </li>
          </template>
          <li
            :key="continue_url"
            class="
              c-navigation__item c-navigation__item--level-1
              padding-y-24
              bp-1024:padding-y-48
              padding-x-24
              display-flex
              justify-content-center
              align-items-center
              position-relative
            "
          >
            <a
              class="
                display-flex
                align-items-center
                c-navigation__url c-navigation__url--level-1
                text-decoration-none
              "
              :title="continue_text"
              :tabindex="is_menu_open ? 0 : -1"
              :href="continue_url"
              @focus="is_menu_open = true"
              @blur="is_menu_open = false"
              role="button"
            >
              <span
                class="
                  margin-l-auto
                  font-weight-bold
                  text-transform-uppercase text-align-right text-align-right
                  font-size-30 font-weight-700
                  line-height-125
                  bp-1024:font-size-48 bp-1024:line-height-72
                "
                >{{ continue_text }}</span
              >
            </a>
          </li>
        </ul>
      </div>
      <div
        v-if="
          this.$root.navigation.sponsor && this.$root.navigation.sponsor.image
        "
        class="
          s-common__header--ad
          margin-l-24
          bp-1024:margin-t-24 bp-1024:margin-l-0
        "
      >
        <a
          :href="this.$root.navigation.sponsor.url"
          v-if="this.$root.navigation.sponsor.url"
        >
          <img
            :src="this.$root.navigation.sponsor.image"
            :alt="this.$root.navigation.headline + ' Sponsor'"
          />
        </a>
        <img
          v-if="!this.$root.navigation.sponsor.url"
          :src="this.$root.navigation.sponsor.image"
          :alt="this.$root.navigation.headline + ' Sponsor'"
        />
      </div>
      <div
        class="
          c-navigation__title
          flex-shrink-1
          position-relative
          font-size-18 font-weight-700
          margin-r-auto
          bp-540:margin-r-0
        "
      >
        {{ this.$root.navigation.navHeadline }}
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "./navigation";

export default {
  extends: Navigation,
  data: () => ({
    mobile_offset: -60,
    desktop_offset: -0,
  }),
};
</script>

<style scoped lang="scss">
.c-navigation {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 120px;
  padding: 48px 24px;
  padding-bottom: 84px;
  display: flex;
  justify-content: center;
  border-right: 1px solid $main-border-color;
  z-index: 110;
  background: $main-color;
  &:after {
    height: 60px;
    content: "";
    width: 2px;
    position: absolute;
    left: 50%;
    bottom: 0px;
    display: block;
    background: $color-safe-text;
    @media screen and (max-width: 1023px) {
      content: none;
    }
  }

  &__home {
    @media screen and (max-width: 1023px) {
      margin-left: auto;
    }
  }
  &__close {
    position: fixed;
    right: 48px;
    top: 48px;
    z-index: 100;
  }
  &__title {
    margin-top: auto;
    writing-mode: tb-rl;
    line-height: 1;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    position: relative;
    min-height: 1px;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    @media screen and (max-width: 1023px) {
      writing-mode: initial;
      transform: rotate(0);
      margin: 0;
      margin-right: auto;
      display: flex;
      align-items: center;
      overflow: visible;
      &:before {
        content: "";
        display: block;
        width: 60px;
        height: 2px;
        margin: 0 24px 0 0;
        background: $main-font-color;
      }
    }
  }
  &__icon {
    @include base_reset();
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-safe-text;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      background: $color-primary;
      color: $text-on-primary;
    }
    @media screen and (max-width: 1023px) {
      width: 60px;
      height: 60px;
    }
  }
  &__app-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $main-color;
    border-left-color: $main-border-color;
    z-index: 2;
    @media screen and (max-width: 1023px) {
      flex-direction: row-reverse;
      width: 100%;
      padding-right: 24px;
    }
  }
  &__menu-button {
    @include base_reset();
    color: $color-safe-text;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    margin-bottom: 24px;
    transition: 0.3s background;
    cursor: pointer;
    @media screen and (max-width: 1023px) {
      margin-bottom: 0;
      position: fixed;
      left: 50%;
      margin: 0;
      margin-left: -115px;
      bottom: 24px;
      width: 230px;
      height: 54px;
      background: $main-color;
      border-radius: 27px;
      transition: 0.3s background;
      z-index: 10000;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    }
    &:hover {
      background: $color-primary;
      color: $text-on-primary;
    }
  }
  &__site {
    writing-mode: vertical-lr;
    position: relative;
    min-height: 1px;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 48px 0;
    &::before,
    &::after {
      position: absolute;
      right: 50%;
      top: 0;
      width: 1px;
      height: 36px;
      content: "";
      background: $main-font-color;
    }
    &::after {
      top: auto;
      bottom: 0;
    }
  }
  &__logo {
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 2;
    img {
      max-height: 100%;
    }
  }
  &__offcanvas {
    top: 0;
    left: 120px;
    opacity: 0;
    pointer-events: none;
    z-index: 10000;
    transition: 0.3s;
    height: 100%;
    width: calc(100% - 120px);
    background: $main-color;
    padding: 0;
    display: flex;
    z-index: 100;
    &.is-open {
      transform: none;
      opacity: 1;
      pointer-events: all;
    }
    @media screen and (max-width: 1023px) {
      padding-bottom: 102px;
    }
  }
  &__items {
    min-height: 100%;
    flex: 1 1 auto;
    overflow: auto;
    @media screen and (max-width: 1023px) {
      border-bottom: 1px solid $main-border-color;
    }
  }
  &__item--level-1 {
    border-bottom: 1px dashed $main-border-color;
    &-span {
      color: $color_primary;
      font-weight: 700;
      @media screen and (max-width: 1023px) {
        &::after {
          content: "//";
          margin: 0 48px;
        }
      }
      &::after {
        content: "//";
        margin: 0 24px;
      }
    }
  }
  &__url--level-1 {
    color: $main-font-color;
    &::before {
      position: absolute;
      top: 0;
      left: -1px;
      height: 100%;
      width: 3px;
      content: "";
      background: $color-primary;
      opacity: 0;
    }
    &.is-active {
      &::before {
        opacity: 1;
      }
    }
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 1023px) {
    height: 72px;
    width: 100%;
    padding: 0;
    &__offcanvas {
      left: 0;
      width: 100%;
    }
  }
}
</style>
